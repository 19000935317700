














































































































import Vue from 'vue'
import { VueAgile } from 'vue-agile'

export default Vue.extend({
  components: {
    VueAgile,
  },
  async asyncData({ $axios }) {
    const vehiclesMason = await $axios.$get('/vehicles/recent/110')
    const vehiclesPontiac = await $axios.$get('/vehicles/recent/105')
    const banners = await $axios.$get('/hero')
    return { vehiclesMason, vehiclesPontiac, banners }
  },
  head: {
    titleTemplate:
      'Used Car Parts - U Pull and Save Auto Parts, Mason & Pontiac, MI',
  },
})
